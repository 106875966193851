<template>
    <div>
        <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i"
            @resized="sizeChange(item)" @moved="sizeChange(item)" @container-resized="sizeChange(item)" class="gridItem"
            drag-ignore-from=".gridBox" drag-allow-from=".draggable" :is-resizable="isSettings" :is-draggable="isSettings">

            <div class="gridBox">
                <dv-border-box-12 :ref="'box' + item.i">
                    <components :ref="'chart' + item.i" :is="item.component" v-if="item.component"></components>
                    <span v-else></span>
                </dv-border-box-12>
            </div>
            <div class="draggable" v-if="isSettings">
                <i class="el-icon-rank move"></i>
                可拖拽
            </div>
            <div class="handler" v-if="isSettings">
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="edit(item)"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="dele(item.i)"></el-button>
            </div>
        </grid-item>
        <edit v-model="dialogVisible" :data="editData" @success="success" />
    </div>
</template>

<script>
import edit from '../edit/index.vue';
export default {
    props: {
        data: [],
        isSettings: {
            default: false
        }
    },
    components: {
        edit
    },

    data() {
        return {
            //编辑弹窗
            dialogVisible: false,
            editData: {}
        }
    },
    computed: {
        layout: {
            get() {
                return this.data
            },
            set() {
            }
        }
    },
    watch: {
        layout(newVal) {
            for (const key in newVal) {
                if (Object.hasOwnProperty.call(newVal, key)) {
                    this.sizeChange(newVal[key]);
                }
            }
        }
    },
    methods: {
        //尺寸改变
        sizeChange(item) {
            let box = 'box' + item.i
            let chart = 'chart' + item.i
            setTimeout(() => {
                if (item.component) {
                    this.$refs[chart][0].sizechange()
                }
                this.$refs[box][0].initWH()
            }, 100)
        },
        add() {
            this.editData = {}
            this.dialogVisible = true
        },
        edit(item) {
            this.$emit('edit',item)
            // this.editData = JSON.parse(JSON.stringify(item))
            // this.dialogVisible = true
        },
        //删除元素
        dele(i) {
            this.layout.splice(
                this.layout.findIndex((item) => item.i === i),
                1
            );
        },
        success(data) {
            let gridItem = this.layout.findIndex((item) => item.i === data.i)
            if (gridItem > -1) {
                this.layout.splice(
                    gridItem,
                    1, data
                );
            } else {
                let lastItem = this.layout[this.layout.length - 1]
                this.layout.push({
                    x: lastItem ? lastItem.x : 0,
                    y: lastItem ? lastItem.y : 0,
                    w: data.w,
                    h: data.h,
                    component: data.component,
                    i: this.layout.length + 1,
                });
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.gridItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(32, 45, 56, 0.5);
    // border: solid #fff 1px;
    // background-color: #cccccc;

    .draggable {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 100px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        opacity: 0;
        transition: .3s all;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .move {
            font-size: 50px;
        }

        &:hover {
            opacity: 1;
            background: rgba(224, 224, 224, 0.17);
        }

    }

    .gridBox {
        width: 100%;
        height: 100%;
    }

    .handler {
        position: absolute;
        right: 2%;
        top: 4%;
    }
}
</style>