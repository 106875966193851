<template>
    <el-dialog title="组件信息" :visible.sync="visible" width="800px" :before-close="handleClose" append-to-body>
        <el-form ref="form" :model="form" label-width="80px" label-position="top">
            <el-form-item label="组件">
                <!-- <el-radio-group v-model="form.component">
                    <el-radio :label="item.name" v-for="(item, index) in componentList" :key="index">{{ item.title
                    }}</el-radio>
                </el-radio-group> -->
                <div class="component-box">
                    <el-row :gutter="10">
                        <el-col :span="6" v-for="(item, index) in componentList" :key="index">
                            <div @click.stop="selectChange(item)" class="box-main">
                                <el-card :body-style="{
                                    padding: '0px', textAlign: 'center'
                                }" class="card-body">
                                    <img :src="item.image" class="image">
                                    <div style="padding: 14px;">
                                        <span>{{ item.title }}</span>
                                    </div>
                                </el-card>
                                <div class="mask" :class="{ 'selected': item.name == form.component }"></div>
                            </div>

                        </el-col>
                    </el-row>
                </div>
            </el-form-item>

            <!-- <div class="show-box">
                <div v-for="(item, index) in this.componentList" :key="index" class="box-item">
                    <div class="item-content">
                        <component :is="item.name"></component>
                    </div>

                </div>
            </div> -->


        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import componentList from './index'
export default {
    props: {
        value: {
            default: false,
        },
        data: {
            default: () => { }
        }
    },
    data() {
        return {
            visible: false,
            form: {
                component: ''
            }
        }
    },
    computed: {
        componentList() {
            return componentList
        }
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal) {
                    this.init()
                }
                this.visible = newVal
            },
            immediate: true

        },
        visible(val) {
            this.$emit("input", val);
        }
    },
    methods: {
        init() {
            this.form = {
                x: '',
                y: '',
                w: 4,
                h: 4,
                i: "",
                component: "",
            }
            for (const key in this.data) {
                if (Object.hasOwnProperty.call(this.data, key)) {
                    this.form[key] = this.data[key]
                }
            }
        },
        handleClose(done) {
            done()
        },
        submit() {
            this.$emit('success', JSON.parse(JSON.stringify(this.form)))
            this.visible = false
        },
        selectChange(item) {
            this.form.component = item.name
        }
    }
}
</script>

<style lang="scss" scoped>
.show-box {
    display: flex;
    flex-wrap: wrap;

    .box-item {
        width: 50%;
        height: 200px;
        padding: 5px;

        .item-content {
            background: #111720;
            width: 100%;
            height: 100%;
        }
    }
}

.component-box {
    .image {
        width: auto;
        height: 100px;
    }

    .box-main {
        position: relative;
        margin-bottom: 10px;

        .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            cursor: pointer;

            &.selected {
                border: 2px solid #4D99F9;
            }
        }
    }

    .card-body {
        padding: 5px;
        cursor: pointer;
        position: relative;



    }
}
</style>