<template>
    <div class="contanier" id="index" style="overflow-y: auto;overflow-x: hidden;">
        <dv-loading v-if="loading">Loading...</dv-loading>
        <!-- 头部 -->
        <div class="bg" v-else>
            <div class="header">
                <h2>嗨小克公寓运营统计数据大屏</h2>
                <p>{{ dateYear }} {{ dateWeek }} {{ dateDay }}
                </p>
            </div>
            <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
            <div class="host-body">
                <div class="d-flex jc-center">
                    <dv-decoration-10 class="dv-dec-10" />
                    <div class="d-flex jc-center">
                        <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
                        <div class="title">
                            <!-- <span class="title-text">嗨小克公寓运营统计数据大屏</span> -->
                            <dv-decoration-5 style="width:100%;height:40px;" />
                            <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
                        </div>
                        <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
                    </div>
                    <dv-decoration-10 class="dv-dec-10-s" />
                </div>
                <div class="d-flex jc-between px-2">
                    <div class="d-flex aside-width">
                        <div class="react-left ml-4 react-l-s">
                            <span class="react-left"></span>
                            <span class="text">数据分析</span>
                        </div>
                        <div class="react-left ml-3">
                            <span class="text">数据分析</span>
                        </div>
                    </div>
                    <div class="d-flex aside-width">
                        <div class="react-right bg-color-blue mr-3">
                            <span class="text fw-b">大屏数据</span>
                        </div>
                        <div class="react-right mr-4 react-l-s">
                            <span class="react-after"></span>
                            <div class="text" v-show="isSettings">
                                <div style="display: flex; align-items: center;">
                                    <el-button type="primary" icon="el-icon-s-tools" size="mini" style="margin-right: 20px;"
                                        @click="layoutVisible = true">更换布局</el-button>
                                    <el-button type="primary" icon="el-icon-plus" size="mini" style="margin-right: 20px;"
                                        @click="handleAdd">添加</el-button>
                                    <span style="position: relative;">
                                        <el-button type="text" icon="el-icon-check" class="handler-box"
                                            @click="save(true)">保存</el-button>
                                        <el-button type="text" icon="el-icon-close" class="handler-box"
                                            @click="save(false)">取消</el-button>
                                    </span>
                                </div>

                            </div>
                            <div class="text" v-show="!isSettings" @click="handleSettings"><i
                                    class="el-icon-s-tools handler-box settings"></i></div>
                        </div>
                    </div>
                </div>
                <div class="content-container">
                    <grid-layout :layout.sync="layout" :col-num="12" :row-height="50" :is-draggable="true"
                        :is-resizable="true" :is-mirrored="false" :vertical-compact="true" :margin="[10, 10]"
                        :use-css-transforms="true" ref="gridLayout">
                        <template v-if="isSettings">
                            <grid-item v-for="item in 216" :x="((item - 1) % 12)" :y="1 * (Math.ceil(item / 12)) - 1" :w="1"
                                :h="1" :i="item" :key="item.i" class="backGridItem" :is-draggable="false"
                                :is-resizable="false">
                            </grid-item>
                        </template>

                        <!-- <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i"
                            :key="item.i" @resized="sizeChange(item)" @moved="sizeChange(item)"
                            @container-resized="sizeChange(item)" class="gridItem" drag-ignore-from=".gridBox"
                            drag-allow-from=".draggable" :is-resizable="isSettings" :is-draggable="isSettings">

                            <div class="gridBox"><dv-border-box-12 :ref="'box' + item.i">
                                    <components :ref="'chart' + item.i" :is="item.component"></components>
                                </dv-border-box-12></div>
                            <div class="draggable" v-if="isSettings">
                                <i class="el-icon-rank move"></i>
                                可拖拽
                            </div>
                            <i class="el-icon-close delete" @click="dele(item.i)" v-if="isSettings"></i>
                        </grid-item> -->
                        <myGridItem :data.sync="layout" :isSettings="isSettings" ref="myGridItem" @edit="handleEdit" />
                    </grid-layout>
                </div>
            </div>
        </div>
        <layoutDialog v-model="layoutVisible" />
        <add v-model="addDialogVisible" @success="addCallback" :data="addData" />
    </div>
</template>

<script>
import { formatTime } from '@/utils/index.js'
import layoutDialog from './components/layout/index.vue'
import myGridItem from './components/myGridItem/index.vue';
import add from './components/edit/index.vue'
import { mapState } from 'vuex';
import { defaultPreviewData } from '@/utils/defaultPreview'
export default {
    data() {
        return {
            time: null,
            loading: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            decorationColor: ['#568aea', '#000000'],
            //布局弹窗
            layoutVisible: false,
            // 定义栅格系统数据源
            layout: [
                // { "x": 0, "y": 0, "w": 4, "h": 6, "i": "0", name: '租房年龄比例', component: 'yonghu', show: true },
                // { "x": 4, "y": 0, "w": 4, "h": 6, "i": "1", name: '共享房源数', component: 'bili', show: true },
                // { "x": 8, "y": 0, "w": 4, "h": 6, "i": "2", name: '活跃经济人比例', component: 'jingjiren', show: true },
                // { "x": 0, "y": 6, "w": 4, "h": 6, "i": "3", name: '共享房源数', component: 'fangyuan', show: true },
                // { "x": 4, "y": 6, "w": 4, "h": 6, "i": "4", name: '实时在线经济人', component: 'jj', show: true },
                // { "x": 8, "y": 6, "w": 4, "h": 6, "i": "5", name: '活跃机构比例', component: 'jigou', show: true },
                // { "x": 0, "y": 12, "w": 4, "h": 6, "i": "6", name: '上半年销售计划', component: 'jihua', show: true },
                // { "x": 4, "y": 12, "w": 4, "h": 6, "i": "7", name: '租房热度', component: 'redu', show: true },
                // { "x": 8, "y": 12, "w": 4, "h": 6, "i": "8" },
            ],
            //旧布局
            oldLayout: [],
            //是否设置
            isSettings: false,
            //添加弹窗显示
            addDialogVisible: false,
            addData: null

        };
    },
    components: {
        layoutDialog,
        myGridItem,
        add
    },
    computed: {
        dragOptions() {
            return {
                animation: 300,
                group: {
                    name: 'group',
                    pull: true,
                    put: true
                },
                ghostClass: 'ghost',
                forceFallback: true,
                handle: ".item"
            }
        },
        gridSpan() {
            return (wSpan, hSpan = 3) => {
                return {
                    'grid-column-start': `span ${wSpan}`,
                    'width': '100%',
                    'grid-row-start': `span ${hSpan}`
                }
            }
        },
        showList: {
            get() {
                return this.layout.filter(item => item.show)
            }, set() {

            }
        },
        ...mapState({
            defaultPreviewIndex: state => state.layout.defaultPreviewIndex
        })
    },
    watch: {
        defaultPreviewIndex(newVal) {
            this.layout = defaultPreviewData[newVal].layout
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.timeFn()
        this.cancelLoading()
    },
    beforeDestroy() {
        clearInterval(this.timing)
    },
    methods: {
        //初始化渲染
        init() {
            let layout = localStorage.getItem('layout')
            if (layout) {
                this.layout = JSON.parse(localStorage.getItem('layout'))
            } else {
                this.layout = defaultPreviewData[this.defaultPreviewIndex].layout
            }
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        sizeChange(item) {
            let box = 'box' + item.i
            let chart = 'chart' + item.i
            setTimeout(() => {
                if (item.component) {
                    this.$refs[chart][0].sizechange()
                }
                this.$refs[box][0].initWH()
            }, 100)
        },
        //开启布局设置
        handleSettings() {
            this.oldLayout = JSON.parse(JSON.stringify(this.layout))
            this.isSettings = true
        },
        //删除元素
        dele(i) {
            this.layout.splice(
                this.layout.findIndex((item) => item.i === i),
                1
            );
        },
        //保存布局
        save(value) {
            if (value) {
                localStorage.setItem('layout', JSON.stringify(this.layout))
                localStorage.setItem('defaultPreviewIndex', this.defaultPreviewIndex)
                this.$message.success('操作成功')
            } else {
                this.layout = this.oldLayout
            }
            this.isSettings = !this.isSettings
        },
        handleAdd() {
            // this.$refs.myGridItem.add()
            this.addDialogVisible = true
        },
        handleEdit(item) {
            this.addData = item
            this.addDialogVisible = true
        },
        addCallback(data) {
            // console.log('data',data);
            let index = this.layout.findIndex((item) => item.i === data.i)
            // console.log('index', index);
            if (index > -1) {
                console.log('edit');
                this.layout[index].component = data.component

                this.layout.splice(index,1, data
                );
            } else {
                let lastItem = this.layout[this.layout.length - 1]
                this.layout.push({
                    x: lastItem ? lastItem.x : 0,
                    y: lastItem ? lastItem.y : 0,
                    w: 4,
                    h: 4,
                    component: data.component,
                    i: this.layout.length + 1,
                });
            }

            this.addData = {}

        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/new.scss';

.contanier {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    // overflow: hidden;
    overflow: auto;
    background: url(/@/assets/images/bg.jpg) no-repeat top center;
    background-size: cover;

    &::-webkit-scrollbar {
        display: none;
    }

    /* 头部 header */
    .header {
        height: 80px;
        line-height: 80px;
        // background: url(@/assets/images/head_bg.png) no-repeat;
        background-size: 100% 100%;
        color: #fff;
        position: relative;
        text-align: center;

        h2 {
            flex: 1;
            font-size: 30px;
        }

        p {
            position: absolute;
            right: 30px;
            top: -10px;
        }
    }

    /* 主体 content */
    .content-container {
        position: relative;
        height: 100%;
        // overflow: auto;
        // background-color: rgb(239, 243, 247);
    }

    .content {
        position: relative;
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-auto-rows: 80px;
        grid-auto-flow: row dense;
        gap: 15px;
        padding: 10px;
        padding-bottom: 20px;
        // height: 100%;

        .item {
            width: 100%;
            // height: 300px;
            overflow: hidden;
            position: relative;
            padding: 5px;
            // margin: 10px;
            box-sizing: border-box;

            // &::before {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 10px;
            //     height: 10px;
            //     border-left: 2px solid #02a6b5;
            //     border-top: 2px solid #02a6b5;
            //     content: "";
            // }

            // &::after {
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     width: 10px;
            //     height: 10px;
            //     border-right: 2px solid #02a6b5;
            //     border-top: 2px solid #02a6b5;
            //     content: "";
            // }

            .com-item {
                position: relative;
                padding: 10px;
                // background: url(../static/images/line.png) rgba(255, 255, 255, 0.03);
                background-size: cover;
                border: 1px solid rgba(25, 186, 139, 0.17);
                width: 100%;
                // height: 300px;
                height: 100%;
                overflow: hidden;
                box-sizing: border-box;
            }

            .item-footer {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;

                // &::before {
                //     position: absolute;
                //     bottom: 0;
                //     right: 0;
                //     width: 10px;
                //     height: 10px;
                //     border-right: 2px solid #02a6b5;
                //     border-bottom: 2px solid #02a6b5;
                //     content: "";
                // }

                // &::after {
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 10px;
                //     height: 10px;
                //     border-left: 2px solid #02a6b5;
                //     border-bottom: 2px solid #02a6b5;
                //     content: "";
                // }
            }
        }



        .ghost {
            opacity: .7;
            background: #E3DAD4;

            span {
                display: none;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                padding: 8px;
                cursor: pointer;
                // background-color: red;
            }

            &::after {
                color: #fff;
                content: "(^_^)~";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.handler-box {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: all .5s;


    &:hover {
        color: #00DEDF;
    }

}

.gridItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(17, 18, 36, 0.17);
    // border: solid #fff 1px;
    // background-color: #cccccc;

    .draggable {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 100px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        opacity: 0;
        transition: .3s all;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .move {
            font-size: 50px;
        }

        &:hover {
            opacity: 1;
            background: rgba(224, 224, 224, 0.17);
        }

    }

    .gridBox {
        width: 100%;
        height: 100%;
    }

    .delete {
        position: absolute;
        right: 0%;
        top: 0%;
        font-size: 22px;
        font-weight: bold;
        cursor: pointer;
        transition: all .3s;
        background: #eee;
        color: #000;
        border-radius: 50%;

        &:hover {
            color: red;

        }
    }
}

.backGridItem {
    color: #fff;
    // border: dashed #ccc 1px;
    border: solid #061F33 1px;
}
</style>