<template>
    <el-drawer title="布局" :visible.sync="drawer" :direction="direction" :before-close="handleClose" :append-to-body="true"
        size="400px">

        <el-card v-for="(item, index) in list" :key="index">
            <div @click.stop="select(item, index)" class="item">

                <div class="gridHeader">
                    <div class="title">{{ item.name }}</div>
                    <div class="handleBox" v-if="index == defaultPreviewIndex">
                        <!-- <el-button size="mini" type="primary">编辑</el-button> -->
                        <!-- <el-button size="mini">恢复默认</el-button> -->
                    </div>
                </div>

                <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image" style="max-width: 100%;max-height: 200px;"> -->
                <el-row :gutter="10">
                    <el-col :span="24">
                        <div class="gridShow">
                            <div>
                                <grid-layout :layout.sync="item.layout" :col-num="12" :row-height="5" :is-draggable="true"
                                    :is-resizable="true" :is-mirrored="false" :vertical-compact="true" :margin="[5, 5]"
                                    :use-css-transforms="true" ref="gridLayout" style="overflow: hidden;">
                                    <grid-item v-for="item1 in item.layout" :x="item1.x" :y="item1.y" :w="item1.w"
                                        :h="item1.h" :i="item1.i" :key="item1.i" class="gridItem"
                                        drag-ignore-from=".gridBox" drag-allow-from=".draggable" :is-resizable="false"
                                        :is-draggable="false">
                                    </grid-item>
                                </grid-layout>
                            </div>

                            <div class="mask" :class="{ 'checked': index == defaultPreviewIndex }">
                                <i class="el-icon-success icon"></i>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </el-card>
    </el-drawer>
</template>

<script>
import { defaultPreviewData } from '@/utils/defaultPreview'
import { mapState } from 'vuex'
export default {
    props: {
        value: {
            default: false
        },
    },
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            list: []
        }
    },
    computed: {
        ...mapState({
            defaultPreviewIndex: state => state.layout.defaultPreviewIndex
        })
    },
    watch: {
        value: {
            handler(newVal) {
                // if(newVal){
                //     console.log('list',this.list);
                // }
                this.drawer = newVal
            },
            immediate: true

        },
        drawer(val) {
            this.$emit("input", val);
        },
    },
    created() {
        this.list = JSON.parse(JSON.stringify(defaultPreviewData))
    },
    methods: {
        handleClose(done) {
            done()
        },
        //选择布局
        select(item, index) {
            this.$store.commit("layout/setDefaultPreviewIndex", index);
            item.show = !item.show
        },
        restore() {
            console.log('默认');
        },
    }
}
</script>

<style lang="scss" scoped>
.item {
    z-index: 999;
    position: relative;
    cursor: pointer;

    &.checked {
        background: rgba(203, 192, 192, .5);
    }

    .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        transition: all .4s linear;

        &.checked {
            border: 2px solid #2879FF;
            color: #2879FF;
            font-size: 40px;
            background: rgba(203, 192, 192, .2);
        }

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            font-weight: bold;
        }
    }

}

.gridHeader {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}


.gridShow {
    width: 100%;
    position: relative;
}

.gridItem {
    background: #999;
}
</style>