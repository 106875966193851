const importFn = require.context("@/components/common/", true, /\.vue$/);
const componentList = [];
let imagesList = [
  {
    name: "bili",
    image: require("@/assets/images/components/bili.png"),
  },
  {
    name: "fangyuan",
    image: require("@/assets/images/components/fangyuan.png"),
  },
  {
    name: "jihua",
    image: require("@/assets/images/components/jihua.png"),
  },
  {
    name: "jj",
    image: require("@/assets/images/components/jj.png"),
  },
  {
    name: "jigou",
    image: require("@/assets/images/components/jigou.png"),
  },
  {
    name: "jingjiren",
    image: require("@/assets/images/components/jingjiren.png"),
  },
  {
    name: "redu",
    image: require("@/assets/images/components/redu.png"),
  },
  {
    name: "yonghu",
    image: require("@/assets/images/components/yonghu.png"),
  },
];
importFn.keys().forEach((path) => {
  
  const component = importFn(path).default;
  if (component.name && component.title) {
    let imageObj = imagesList.find(item=>item.name == component.name)
    componentList.push({
      name: component.name,
      title: component.title,
      image:imageObj.image
    });
  }
});
export default componentList;
