export const defaultPreviewData = [
  {
    name: "默认布局",
    layout: [
      {
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        i: "0",
        name: "租房年龄比例",
        component: "yonghu",
        show: true,
        moved: false,
      },
      {
        x: 4,
        y: 0,
        w: 4,
        h: 4,
        i: "1",
        name: "共享房源数",
        component: "bili",
        show: true,
        moved: false,
      },
      {
        x: 8,
        y: 0,
        w: 4,
        h: 4,
        i: "2",
        name: "活跃经济人比例",
        component: "jingjiren",
        show: true,
        moved: false,
      },
      {
        x: 0,
        y: 4,
        w: 4,
        h: 4,
        i: "3",
        name: "共享房源数",
        component: "fangyuan",
        show: true,
        moved: false,
      },
      {
        x: 4,
        y: 4,
        w: 4,
        h: 4,
        i: "4",
        name: "实时在线经济人",
        component: "jj",
        show: true,
        moved: false,
      },
      {
        x: 8,
        y: 4,
        w: 4,
        h: 4,
        i: "5",
        name: "活跃机构比例",
        component: "jigou",
        show: true,
        moved: false,
      },
      {
        x: 0,
        y: 8,
        w: 4,
        h: 4,
        i: "6",
        name: "上半年销售计划",
        component: "jihua",
        show: true,
        moved: false,
      },
      {
        x: 4,
        y: 8,
        w: 4,
        h: 4,
        i: "7",
        name: "租房热度",
        component: "redu",
        show: true,
        moved: false,
      },
      { x: 8, y: 8, w: 4, h: 4, i: "8", moved: false },
    ],
  },
  {
    name: "布局1",
    layout: [
      {
        component: "jihua",
        x: 0,
        y: 5,
        w: 3,
        h: 4,
        i: "标准1*7",
        moved: false,
      },
      {
        component: "fangyuan",
        x: 3,
        y: 0,
        w: 6,
        h: 5,
        i: "标准1*8",
        moved: false,
      },
      {
        component: "jigou",
        x: 9,
        y: 0,
        w: 3,
        h: 5,
        i: "标准1*1",
        moved: false,
      },
      {
        component: "jingjiren",
        x: 0,
        y: 0,
        w: 3,
        h: 5,
        i: "标准1*12",
        moved: false,
      },
      {
        x: 3,
        y: 5,
        w: 6,
        h: 8,
        i: "标准1*13",
        moved: false,
        component: "yonghu",
      },
      {
        component: "redu",
        x: 9,
        y: 5,
        w: 3,
        h: 4,
        i: "标准1*14",
        moved: false,
      },
      {
        component: "bili",
        x: 0,
        y: 9,
        w: 3,
        h: 4,
        i: "标准1*15",
        moved: false,
      },
      { component: "jj", x: 9, y: 9, w: 3, h: 4, i: "标准1*16", moved: false },
    ],
  },
  {
    name: "布局2",
    layout: [
      {
        component: "yonghu",
        x: 0,
        y: 5,
        w: 3,
        h: 9,
        i: "标准1*7",
        moved: false,
      },
      {
        component: "jingjiren",
        x: 3,
        y: 0,
        w: 3,
        h: 5,
        i: "标准1*8",
        moved: false,
      },
      {
        component: "jigou",
        x: 6,
        y: 0,
        w: 3,
        h: 5,
        i: "标准1*1",
        moved: false,
      },
      {
        component: "bili",
        x: 0,
        y: 0,
        w: 3,
        h: 5,
        i: "标准1*12",
        moved: false,
      },
      {
        component: "fangyuan",
        x: 3,
        y: 5,
        w: 6,
        h: 9,
        i: "标准1*13",
        moved: false,
      },
      { component: "jj", x: 9, y: 0, w: 3, h: 5, i: "标准1*14", moved: false },
      {
        component: "jihua",
        x: 0,
        y: 14,
        w: 12,
        h: 4,
        i: "标准1*15",
        moved: false,
      },
      {
        component: "redu",
        x: 9,
        y: 5,
        w: 3,
        h: 9,
        i: "标准1*16",
        moved: false,
      },
    ],
  },
];
